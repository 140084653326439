<template>
    <div>
        <perfect-scrollbar class="detect-style-sc">
            <div class="image-list">
                <template v-if="appConfig.detect_index">
                    <img class="bg-image" :src="appConfig.detect_index" alt="">
                </template>
                <template v-else>
                    <template v-for="idx of 8">
                        <img :key="idx" class="bg-image"
                             :src="`https://static.ycsd.work/collocation-helper/detect-thumb3/${idx - 1 }.jpeg`" alt="">
                    </template>
                </template>
            </div>
        </perfect-scrollbar>

        <div class="detect-btn-container">
            <div class="detect-btn">
                <span>免费开始检测</span>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "DetectStyle",
    props: {
        appConfig: Object
    },
    data() {
        return {};
    },
    methods: {
        tabShow() {
            this.init();
        },
        init() {

        },
    }
}
</script>

<style lang="scss" scoped>

.detect-style-sc {
    height: 560px;
}

.detect-btn-container {
    position: absolute;
    bottom: 85px;
    left: 0;
    width: 100%;
}

.detect-btn {
    width: 80%;
    height: 36px;
    background: #8EF4EC;
    border-radius: 6px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-image {
    width: 100%;
    margin-top: -1px;
}

.image-list {
    line-height: 0;
    padding-bottom: 22px;
}
</style>