<template>
    <div class="full-gu">
        <div v-if="appConfig.guide_image" class="guide-container"
             :style="`background-image: url(${appConfig.guide_image})`">
            <div class="guide-time">
                {{ appConfig.guide_time }} 跳过
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MpGuid",
    props: {
        appConfig: Object
    },
    data() {
        return {}
    }
}
</script>

<style scoped lang="less">
.guide-container,.full-gu {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.guide-time {
    position: absolute;
    right: 10px;
    bottom: 75px;

    padding: 5px 10px;

    min-width: 60px;

    color: white;
    background-color: #ddd;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>