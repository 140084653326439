<template>
    <div>
        <a-menu v-model="nav" mode="horizontal" @select="navChanged">
            <a-menu-item key="pages">
                <a-icon type="appstore"/>
                <span>底部导航</span>
            </a-menu-item>
            <a-menu-item key="guide">
                <a-icon type="compass"/>
                <span>引导页</span>
            </a-menu-item>
        </a-menu>
        <template v-if="config">
            <div v-if="nav[0] === 'pages' ">
                <m-tabs @tabChange="tabChange" @preview="preview" @change="saveChange" :config="config"/>
            </div>
            <div v-else-if="nav[0] === 'guide' ">
                <m-guide @change="saveChange"  @preview="preview" :config="config"/>
            </div>

            <div class="mp-container" v-if="config" style=" pointer-events: none; ">
                <a-affix :offset-top="120">
                    <mp ref="mp" :app-config="config" :current="current"/>
                </a-affix>
            </div>
        </template>
    </div>
</template>

<script>
import api from "@/repo/api";
import MTabs from "@/views/AST/Xcx/Items/MTabs.vue";
import MGuide from "@/views/AST/Xcx/Items/MGuide.vue";
import Mp from "@/views/AST/Xcx/Mp/Mp.vue";

export default {
    name: "Item",
    components: {Mp, MGuide, MTabs},
    data() {
        return {
            config: undefined,
            nav: ["pages"],
            defaultPage: "custom",
            current: "",
        }
    },
    mounted() {
        this.current = this.defaultPage

        this.fetchConfig()
    },
    methods: {
        navChanged(nav) {
            this.current = (nav.key === 'guide') ? 'guide' : this.defaultPage;
        },
        tabChange(tab) {
            this.current = tab
        },
        fetchConfig() {
            api.get('/ast-app/config', (data) => {

                if (data.code === 0) {
                    this.config = data.data
                }

            })
        },
        preview(group, config) {

            for (const configKey in config) {
                this.$set(this.config, configKey, config[configKey])
            }

            this.$refs['mp'].init()
        },
        saveChange(group, config) {

            this.$loading.show()

            api.post('/ast-app/save-config', {group, config}, (data) => {

                this.$loading.hide()

                if(group !== 'master-info') this.$message.data(data)
            })

        },
    }
}
</script>

<style scoped lang="less">
.mp-container {
    position: absolute;
    top: 100px;
    right: 90px;
}
</style>