<template>
    <div class="one-box">

        <div class="head">
            <img alt="" :src="adminInfo.background || bgUrl">
        </div>

        <div class="body">
            <div class="skew-wrap">
                <div class="skew"></div>
                <div class="content-wrap">
                    <div class="avatar">
                        <img alt="" :src="adminInfo.avatar" v-if="adminInfo.avatar">
                    </div>
                    <div class="video-box">
                        <div class="icon">
                            <img alt="" src="/assets/mp-static/images/one-icon2.png">
                        </div>
                        <div class="video" v-if="video.enabled">
                            <img alt="" src="/assets/mp-static/images/one-icon3.png">
                            <span>视频号</span>
                        </div>
                    </div>
                    <div class="name">{{ adminInfo.name }}</div>
                    <div class="summary">{{ adminInfo.summary }}</div>
                    <div class="contact-information">
                        <div class="item">
                            <div class="icon">
                                <img alt="" src="/assets/mp-static/images/one-icon4.png">
                            </div>
                            <div class="text">
                                <span v-if="showMobile">Tel. {{ adminInfo.phone }}</span>
                                <span v-else>Tel.
                                    {{ adminInfo.phone.substr(0, 3) + "****" + adminInfo.phone.substr(7) }}
                                </span>
                            </div>
                        </div>
                        <div class="item">
                            <div class="icon">
                                <img alt="" src="/assets/mp-static/images/one-icon5.png">
                            </div>
                            <div class="text">
                                <span>E-mail. {{ adminInfo.email }}</span>
                            </div>
                        </div>
                        <div class="item">
                            <div class="icon">
                                <img alt="" src="/assets/mp-static/images/one-icon6.png">
                            </div>
                            <div class="text">
                                <span>Address. {{ adminInfo.address }}</span>
                            </div>
                        </div>
                        <div class="more-box">
                            <div class="more">
                                <img alt="" class="more-bg" src="/assets/mp-static/images/one-btn.png">
                                <div class="more-content">
                                    <span>更多资料</span>
                                    <img alt="" src="/assets/mp-static/images/user-four-right.png">
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showRobot" class="ai-box">
            <div class="head-box">
                <img alt="" src="/assets/mp-static/images/AI-head.png">
            </div>
            <div class="eye-box">
                <img alt="" src="/assets/mp-static/images/AI-eye.png">
                <img alt="" src="/assets/mp-static/images/AI-eye.png">
            </div>
        </div>


    </div>
</template>

<script>
export default {
    name: "MpCard",
    props: {
        bottom: {
            default: 50
        },
        appConfig: Object
    },
    data() {
        return {
            adminInfo: {},
            video: {},
            showMobile: true,
            showRobot: false,
            bgUrl: "https://static.ycsd.work/collocation-helper/one-head-img.png",
        };
    },
    watch: {
        appConfig: {
            handler() {
                this.init()
            },
            deep: true
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            // 主理人信息
            this.adminInfo = this.appConfig.master_info;

            console.log(this.adminInfo);

            this.showRobot = this.appConfig.index_robot || false;

            this.showMobile = this.appConfig.index_show_mobile;

            this.video = this.appConfig.video_account;

        }
    }
}
</script>

<style lang="scss" scoped>
@keyframes rotate {
    0% {
        transform: rotateX(0deg);
    }

    10% {
        transform: rotateX(90deg);
    }

    20% {
        transform: rotateX(180deg);
    }

    30% {
        transform: rotateX(270deg);
    }

    40% {
        transform: rotateX(360deg);
    }

    100% {
        transform: rotateX(360deg);
    }
}

.ai-box {
    position: absolute;
    right: 12px;
    bottom: 146px;
    z-index: 99;

    .head-box {
        width: 48px;
        height: 45px;

        image {
            width: 48px;
            height: 45px;
        }
    }

    .eye-box {
        width: 19px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 15px;
        left: 15px;

        img {
            animation: 3s rotate infinite;
            width: 6px;
            height: 10px;
        }
    }
}

.detect-box {
    bottom: 225px;
}

.one-box {
    width: 100%;
    min-height: 110%;
    background: #8B6D5F;

    .head {
        image {
            width: 100%;
        }
    }

    .body {
        .skew-wrap {
            width: 100%;
            height: 400px;
            overflow: hidden;
            margin-top: -97px;
            position: relative;

            .skew {
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.2);
                backdrop-filter: blur(37px);
                transform: skew(0, 170deg);
                margin-top: 9%;
            }

            .content-wrap {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                padding: 0 12px;
                box-sizing: border-box;

                .avatar {
                    width: 48px;
                    height: 48px;
                    margin-top: 15px;
                    margin-left: 4px;
                    border-radius: 12px;
                    background: #FFFFFF;
                    border: 1px solid #FFFFFF;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 16px;
                    }
                }

                .video-box {
                    position: absolute;
                    top: 50px;
                    right: 12px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .icon {
                        width: 18px;
                        height: 18px;
                        background: rgba(245, 245, 245, 0.1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;

                        img {
                            width: 10px;
                            height: 10px;
                        }
                    }

                    .video {
                        width: 60px;
                        height: 18px;
                        background: rgba(245, 245, 245, 0.1);
                        border-radius: 9px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        margin-left: 12px;

                        img {
                            width: 12px;
                            margin-right: 1px;
                        }

                        span {
                            color: #FBB03B;
                            font-size: 12px;
                            font-weight: bold;
                        }
                    }
                }

                .name {
                    color: #FFFFFF;
                    font-size: 14px;
                    font-weight: bold;
                    margin-top: 10px;
                    line-height: 16px;
                }

                .summary {
                    color: #FFFFFF;
                    font-size: 12px;
                    line-height: 18px;
                    margin-top: 12px;
                }

                .contact-information {
                    width: 100%;
                    background: rgba(255, 255, 255, 0.1);
                    border-radius: 12px;
                    margin: 12px auto 0;
                    padding: 12px 20px;
                    position: relative;
                    box-sizing: border-box;

                    .item {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 12px;

                        .icon {
                            width: 24px;
                            height: 24px;
                            background: #141414;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            margin-right: 6px;

                            img {
                                width: 12px;
                                height: 12px;
                            }
                        }

                        .text {
                            flex: 1;

                            span {
                                color: #FFFFFF;
                                font-size: 10px;
                                line-height: 24px;
                            }
                        }
                    }

                    .more-box {
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;

                        .more {
                            width: 75px;
                            height: 15px;
                            position: relative;
                            line-height: 0;

                            .more-bg {
                                width: 100%;
                                height: 100%;
                            }

                            .more-content {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 2;

                                span {
                                    color: #D1D1CF;
                                    font-size: 10px;
                                    line-height: 15px;
                                    margin-right: 2px;
                                }

                                img {
                                    width: 8px;
                                    height: 8px;
                                }
                            }
                        }


                    }
                }
            }
        }
    }
}

img {
    width: 100%;
}
</style>