<template>
    <span>
        <span @click="activeServiceImageModal">
            <slot></slot>
        </span>

        <at-modal :footer="null" v-model="showServiceImageModal" title="私域客服设置">
            <upload-image :action="action" multiple :imageUrl="images" @del="imagesDelete"
                          :done="activeServiceImageModal"/>
        </at-modal>
    </span>
</template>

<script>
import api from "@/repo/api";
import AtModal from "@/components/AtModal.vue";
import UploadImage from "@/components/UploadImage.vue";

export default {
    name: "ServiceImage",
    components: {UploadImage, AtModal},
    data() {
        return {
            showServiceImageModal: false,
            images: [],
            action: api.colUrl('/ast-master/add-service-image'),
        }
    },
    methods: {
        activeServiceImageModal() {

            this.$loading.show()

            api.get('/ast-master/service-images', (data) => {

                this.$loading.hide()

                if (data.code !== 0) return this.$message.data(data)

                this.images = data.data;

                this.showServiceImageModal = true

            });

        },
        imagesDelete(url) {
            this.$loading.show()

            api.post('/ast-master/delete-service-image', {image: url}, (data) => {

                this.$loading.hide()

                if (data.code !== 0) return this.$message.data(data)

                this.showServiceImageModal = true

            });

        }
    }
}
</script>

<style scoped lang="less">

</style>