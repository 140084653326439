<template>
    <div class="video-box">
        <video class="my-video"
               src="https://static.ycsd.work/video/video-wuman1.mp4" autoplay loop
               muted :controls="false"></video>

        <div class="content-wrap" >
            <div class="take-photo-btn-wrap">
                <div class="take-photo-btn">
                    <img alt="" src="/assets/mp-static/images/home-icon2@2x.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MpDetectAi",
    data() {
        return {
            loading: true,
            adminInfo: {},
            video: {},
            isMaster: false,
            showMobile: true,
            showfir: true,
            showbtn: true,
        };
    },
    methods: {
        tabShow() {
            this.init();
        },
        hideClose() {
            this.showfir = false
        },
        init() {
        },
    }
}
</script>

<style lang="scss" scoped>
@keyframes move {
    0% {
        top: -80vh;
    }

    100% {
        top: 0;
    }
}

.video-box {
    height: 100%;
    background-color: black;
}

.my-video {
    width: 100%;
    height: 90%;
}

.content-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background: none;
}

.take-photo-btn-wrap {
    position: absolute;
    bottom: 128px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .take-photo-btn {
        margin-top: 24px;
        width: 43px;
        height: 43px;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        background: linear-gradient(45deg, #00A4C8 0%, #00FEEB 100%);
        border-radius: 50%;
        img {
            width: 100%;
            height: 100%;
        }
        .btn-icon {
            display: flex;
            flex-direction: column;
            width: 19px;
            height: 18px;
            image {
                width: 19px;
                height: 18px;
            }
        }
    }

}

</style>